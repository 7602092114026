<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card shadow-sm border-0">
        <div class="card-body">
          <div class="row mb-4">
            <div class="col-md-6">
              <label for="from" class="form-label">From:</label>
              <input type="date" id="from" class="form-control" v-model="filterFrom" @change="applyDateFilter"/>
            </div>
            <div class="col-md-6">
              <label for="to" class="form-label">To:</label>
              <input type="date" id="to" class="form-control" v-model="filterTo" @change="applyDateFilter"/>
            </div>
          </div>

          <div class="summary mb-4">
            <h5>Overall Totals</h5>
<!--            <p><span class="bg-warning p-2 text-white">Loris Before Discount:</span>-->
<!--              {{ overallTotals.mainBrand2.before_discount.toFixed(2) }}</p>-->
<!--            <p><span class="bg-warning p-2 text-white">Loris After Discount:</span>-->
<!--              {{ overallTotals.mainBrand2.after_discount.toFixed(2) }}</p>-->
<!--            <p><span class="bg-warning p-2 text-white">Cosmetic Before Discount:</span>-->
<!--              {{ overallTotals.mainBrand1Target1.before_discount.toFixed(2) }}</p>-->
<!--            <p><span class="bg-warning p-2 text-white">Cosmetic After Discount:</span>-->
<!--              {{ overallTotals.mainBrand1Target1.after_discount.toFixed(2) }}</p>-->
<!--            <p><span class="bg-warning p-2 text-white">ليست وكالتنا Before Discount:</span>-->
<!--              {{ overallTotals.mainBrand0.before_discount.toFixed(2) }}</p>-->
<!--            <p><span class="bg-warning p-2 text-white">ليست وكالتنا After Discount:</span>-->
<!--              {{ overallTotals.mainBrand0.after_discount.toFixed(2) }}</p>-->
<!--            <p><span class="bg-warning p-2 text-white">Total Before Discount:</span>-->
<!--              {{-->
<!--                (overallTotals.mainBrand2.before_discount + overallTotals.mainBrand1Target1.before_discount + overallTotals.mainBrand0.before_discount).toFixed(2)-->
<!--              }}</p>-->
<!--            <p><span class="bg-warning p-2 text-white">Total After Discount:</span> {{-->
<!--                (overallTotals.mainBrand2.after_discount + overallTotals.mainBrand1Target1.after_discount + overallTotals.mainBrand0.after_discount).toFixed(2)-->
<!--              }}</p>-->
<!--            <p><span class="bg-warning p-2 text-white">Commission Loris:</span> {{ totalCommissionLoris.toFixed(2) }}-->
<!--            </p> &lt;!&ndash; Display total commission &ndash;&gt;-->
<!--            <p><span class="bg-warning p-2 text-white">Commission Cosmetic:</span> {{-->
<!--                totalCommissionCosmetic.toFixed(2)-->
<!--              }}</p> &lt;!&ndash; Display total commission &ndash;&gt;-->
<!--            <p><span class="bg-warning p-2 text-white">Commission Not our brand:</span>-->
<!--              {{ totalCommissionNotBrand.toFixed(2) }}</p> &lt;!&ndash; Display total commission &ndash;&gt;-->
<!--            &lt;!&ndash;            <p>Profit: {{ overallTotals.mainBrand2.after_discount + overallTotals.mainBrand1Target1.after_discount + overallTotals.mainBrand0.after_discount - (totalCommission) }}</p>&ndash;&gt;-->
<!--            <p><span class="bg-warning p-2 text-white">Loris Target:</span>-->
<!--              {{ (overallTotals.mainBrand2.after_discount - totalCommissionLoris).toFixed(2) }}</p>-->
<!--            <p><span class="bg-warning p-2 text-white">Cosmetic Target:</span>-->
<!--              {{ (overallTotals.mainBrand1Target1.after_discount - totalCommissionCosmetic).toFixed(2) }}</p>-->
<!--            &lt;!&ndash;            <p><span class="bg-warning p-2 text-white">Not Our brand Target:</span> {{(overallTotals.mainBrand0.after_discount - totalCommissionNotBrand).toFixed(2) }}</p>&ndash;&gt;-->

<!--            <p><span class="bg-danger text-white p-2">Loris Profit:</span>-->
<!--              {{ lorisProfit }}-->
<!--            </p>-->

<!--            <p><span class="bg-danger text-white p-2">Cosmetic Profit:</span>-->
<!--              {{ cosmeticProfit }}-->
<!--            </p>-->

            <table class="table table-striped table-hover text-center justify-content-center">
              <tr class="bg-secondary">
                <th>#</th>
                <th>Loris</th>
                <th>Cosmetic</th>
                <th>ليست وكالتنا</th>
              </tr>
              <tr>
                <th class="bg-success text-start">Before Discount</th>
                <th class="bg-primary">{{ overallTotals.mainBrand2.before_discount.toFixed(2) }}</th>
                <th class="bg-warning">{{ overallTotals.mainBrand1Target1.before_discount.toFixed(2) }}</th>
                <th class="bg-danger">{{ overallTotals.mainBrand0.before_discount.toFixed(2) }}</th>
              </tr>
              <tr>
                <th class="bg-success text-start">After Discount</th>
                <th class="bg-primary">{{ overallTotals.mainBrand2.after_discount.toFixed(2) }}</th>
                <th class="bg-warning">{{ overallTotals.mainBrand1Target1.after_discount.toFixed(2) }}</th>
                <th class="bg-danger">{{ overallTotals.mainBrand0.before_discount.toFixed(2) }}</th>
              </tr>

              <tr>
                <th class="bg-success text-start">Commission</th>
                <th class="bg-primary">{{ totalCommissionLoris.toFixed(2) }}</th>
                <th class="bg-warning">{{ totalCommissionCosmetic.toFixed(2) }}</th>
                <th class="bg-danger">{{ totalCommissionNotBrand.toFixed(2) }}</th>
              </tr>

              <tr>
                <th class="bg-success text-start">Target</th>
                <th class="bg-primary">{{ (overallTotals.mainBrand2.after_discount - totalCommissionLoris).toFixed(2) }}</th>
                <th class="bg-warning">{{ (overallTotals.mainBrand1Target1.after_discount - totalCommissionCosmetic).toFixed(2) }}</th>
                <th class="bg-danger">-</th>
              </tr>

              <tr>
                <th class="bg-success text-start">Profit</th>
                <th class="bg-primary">{{ lorisProfit }}</th>
                <th class="bg-warning">{{ cosmeticProfit }}</th>
                <th class="bg-danger">-</th>
              </tr>
            </table>


          </div>


          <table class="table table-striped table-hover">
            <thead>
            <tr>
              <th>User Name</th>
              <th>Loris Before Discount</th>
              <th>Loris After Discount</th>
              <th>Cosmetic Before Discount</th>
              <th>Cosmetic After Discount</th>
              <th>ليست وكالتنا Before Discount</th>
              <th>ليست وكالتنا After Discount</th>
              <th>Commission</th> <!-- Added column for Commission -->
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(result, index) in results" :key="index">
              <td>{{ result.first_name }} {{ result.last_name }}</td>
              <td>{{ result.mainBrand2Totals.before_discount }}</td>
              <td>{{ result.mainBrand2Totals.after_discount }}</td>
              <td>{{ result.mainBrand1Target1Totals.before_discount }}</td>
              <td>{{ result.mainBrand1Target1Totals.after_discount }}</td>
              <td>{{ result.mainBrand0Totals.before_discount }}</td>
              <td>{{ result.mainBrand0Totals.after_discount }}</td>
              <td>
                {{ result.mainBrand0Totals.commission + result.mainBrand2Totals.commission + result.mainBrand1Target1Totals.commission }}
              </td> <!-- Check for correct property -->
              <td>
                <router-link :to="{ name: 'AffiliateOrderView', params: { id: result.user_id } }"
                             class="btn btn-warning text-white">View
                </router-link>
              </td>
            </tr>
            <tr v-if="results.length === 0">
              <td colspan="9" class="text-center">No data available</td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';
import {useUserStore} from '../../../store';

export default {
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },

  data() {
    return {
      filterFrom: '',
      filterTo: '',
      results: [],
      overallTotals: {
        mainBrand2: {before_discount: 0, after_discount: 0},
        mainBrand1Target1: {before_discount: 0, after_discount: 0},
        mainBrand0: {before_discount: 0, after_discount: 0},
        all: {before_discount: 0, after_discount: 0}
      },
      totalCommissionLoris: 0,
      totalCommissionCosmetic: 0,
      totalCommissionNotBrand: 0
    };
  },
  created() {
    this.fetchSalesData();
  },


  computed: {
    lorisProfit() {
      const netAmount = this.overallTotals.mainBrand2.after_discount - this.totalCommissionLoris;

      if (netAmount >= 1000 && netAmount <= 2000) {
        return (netAmount * 0.05).toFixed(2);
      } else if (netAmount >= 2001 && netAmount <= 4000) {
        return (netAmount * 0.07).toFixed(2);
      } else if (netAmount >= 4001 && netAmount <= 8000) {
        return (netAmount * 0.08).toFixed(2);
      } else if (netAmount >= 12001 && netAmount <= 17000) {
        return (netAmount * 0.10).toFixed(2);
      } else if (netAmount > 17001) {
        return (netAmount * 0.11).toFixed(2);
      } else {
        return 0;
      }
    },

    cosmeticProfit() {
      const netAmount = this.overallTotals.mainBrand1Target1.after_discount - this.totalCommissionCosmetic;

      if (netAmount >= 1000 && netAmount <= 2500) {
        return (netAmount * 0.03).toFixed(2);
      } else if (netAmount > 2500) {
        return (netAmount * 0.05).toFixed(2);
      } else {
        return 0;
      }
    }

  },

  methods: {

    fetchSalesData(startDate = null, endDate = null) {
      const apiEndpoint = `api/sub-invoices`;
      const params = {
        user_id: this.$route.params.id,
        from: startDate,
        to: endDate,
      };
      axiosInstance
          .get(apiEndpoint, {params})
          .then(({data}) => {
            console.log(data); // Add this line to debug
            this.results = data.results;
            this.overallTotals = data.overallTotals;
            this.calculateTotalCommissionLoris();
            this.calculateTotalCommissionCosmetic();
            this.calculateTotalCommissionNotBrand();
          })
          .catch(error => {
            console.error('API call failed:', error);
            this.results = [];
            this.overallTotals = {
              mainBrand2: {before_discount: 0, after_discount: 0},
              mainBrand1Target1: {before_discount: 0, after_discount: 0},
              mainBrand0: {before_discount: 0, after_discount: 0},
              all: {before_discount: 0, after_discount: 0}
            };
          });
    },

    applyDateFilter() {
      if (this.filterFrom && this.filterTo) {
        const formattedStartDate = new Date(this.filterFrom).toISOString().split('T')[0];
        const formattedEndDate = new Date(this.filterTo).toISOString().split('T')[0];
        this.fetchSalesData(formattedStartDate, formattedEndDate);
      } else {
        this.fetchSalesData();
      }
    },

    calculateTotalCommissionLoris() {
      this.totalCommissionLoris = this.results.reduce((acc, result) => {
        return acc + (result.mainBrand2Totals.commission || 0);
      }, 0);
    },

    calculateTotalCommissionCosmetic() {
      this.totalCommissionCosmetic = this.results.reduce((acc, result) => {
        return acc + (result.mainBrand1Target1Totals.commission || 0);
      }, 0);
    },

    calculateTotalCommissionNotBrand() {
      this.totalCommissionNotBrand = this.results.reduce((acc, result) => {
        return acc + (result.mainBrand0Totals.commission || 0);
      }, 0);
    },

  },
};
</script>
