<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <h3 class="mb-5">Product Refund Panel</h3>

          <table class="table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>User Name</th>
              <th>Location</th>
              <th>Address</th>
              <th>Phone</th>
              <th>Quantity (Order)</th>
              <th>Quantity (After Refund)</th>
              <th>Refund Quantity</th> <!-- Add the header for refund quantity input -->
            </tr>
            </thead>
            <tbody>
            <tr v-for="invoice in refund" :key="invoice.id">
              <th scope="row">{{ invoice.id }}</th>
              <td><img :src="'https://api.loop-delivery.com/product_detail/' + invoice.image"/></td>
              <td>{{ invoice.sku }}</td>
              <td>{{ invoice.title }}</td>
              <td>{{ invoice.phone }}</td>
              <td>{{ invoice.quantity }}</td>
              <td>{{ invoice.refund_quantity }}</td>
              <td>
                <input type="number" v-model="invoice.quantity" :max="invoice.refund_quantity" :min="0"
                       class="form-control h-25"/>
              </td>
            </tr>
            </tbody>
          </table>
          <button @click="submitRefunds" class="btn btn-primary mt-4">Submit Refunds</button>
          <!-- Add a submit button -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
  name: "RefundView",

  data() {
    return {
      refund: [],
    };
  },

  created() {
    this.productSaleLoad();
  },

  methods: {
    productSaleLoad() {
      const page = `api/refund-product/${this.$route.params.id}`;
      axiosInstance.get(page).then(({data}) => {
        console.log(data);
        this.refund = data;
      });
    },

    updateRefundProduct(invoice) {
      const url = `api/update-refund-product/${invoice.id}`;
      const data = {
        quantity: invoice.quantity,
        product_detail_id: invoice.product_detail_id
      };

      console.log('Updating refund with data:', data);

      axiosInstance
          .put(url, data)
          .then(() => {
            console.log('Refund updated successfully');
          })
          .catch(error => {
            console.error('Failed to update refund:', error);
          });
    },

    submitRefunds() {
      this.refund.forEach(invoice => {
        this.updateRefundProduct(invoice);
      });
    }
  },
};
</script>

<style scoped></style>
