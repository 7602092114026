<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card shadow-sm border-0">
        <div class="card-body">

          <button @click="exportToCSV" class="btn btn-primary mb-4">Export to CSV</button>


          <div class="row mb-4">
            <div class="col-md-6">
              <label for="from" class="form-label">From:</label>
              <input type="date" id="from" class="form-control" v-model="filterFrom" @change="applyDateFilter"/>
            </div>
            <div class="col-md-6">
              <label for="to" class="form-label">To:</label>
              <input type="date" id="to" class="form-control" v-model="filterTo" @change="applyDateFilter"/>
            </div>
          </div>

          <div class="summary mb-4">
            <h5>Overall Totals</h5>

            <table class="table table-striped table-hover text-center justify-content-center">
              <tr class="bg-secondary">
                <th>#</th>
                <th>Loris</th>
                <th>Cosmetic</th>
                <th>ليست وكالتنا</th>
              </tr>
              <tr>
                <th class="bg-success text-start">Before Discount</th>
                <th class="bg-primary">{{ overallTotals.mainBrand2.before_discount.toFixed(2) }}</th>
                <th class="bg-warning">{{ overallTotals.mainBrand1Target1.before_discount.toFixed(2) }}</th>
                <th class="bg-danger">{{ overallTotals.mainBrand0.before_discount.toFixed(2) }}</th>
              </tr>
              <tr>
                <th class="bg-success text-start">After Discount</th>
                <th class="bg-primary">{{ overallTotals.mainBrand2.after_discount.toFixed(2) }}</th>
                <th class="bg-warning">{{ overallTotals.mainBrand1Target1.after_discount.toFixed(2) }}</th>
                <th class="bg-danger">{{ overallTotals.mainBrand0.before_discount.toFixed(2) }}</th>
              </tr>

              <tr>
                <th class="bg-success text-start">Commission</th>
                <th class="bg-primary">{{ totalCommissionLoris.toFixed(2) }}</th>
                <th class="bg-warning">{{ totalCommissionCosmetic.toFixed(2) }}</th>
                <th class="bg-danger">{{ totalCommissionNotBrand.toFixed(2) }}</th>
              </tr>

              <tr>
                <th class="bg-success text-start">Target</th>
                <th class="bg-primary">{{ (overallTotals.mainBrand2.after_discount - totalCommissionLoris).toFixed(2) }}</th>
                <th class="bg-warning">{{ (overallTotals.mainBrand1Target1.after_discount - totalCommissionCosmetic).toFixed(2) }}</th>
                <th class="bg-danger">-</th>
              </tr>

              <tr>
                <th class="bg-success text-start">Transfer</th>
                <th class="bg-primary">{{ (totalCommissionTransferLoris).toFixed(2) }}</th>
                <th class="bg-warning">-</th>
                <th class="bg-danger">-</th>
              </tr>

              <tr>
                <th class="bg-success text-start">Profit</th>
                <th class="bg-primary">{{ lorisProfit }}</th>
                <th class="bg-warning">{{ cosmeticProfit }}</th>
                <th class="bg-danger">-</th>
              </tr>
            </table>
            <!--
                      <p><span class="bg-warning p-2 text-white">Loris Before Discount:</span>
                        {{ overallTotals.mainBrand2.before_discount.toFixed(2) }}</p>
                       <p><span class="bg-warning p-2 text-white">Loris After Discount:</span>
                         {{ overallTotals.mainBrand2.after_discount.toFixed(2) }}</p>
                       <p><span class="bg-warning p-2 text-white">Cosmetic Before Discount:</span>
                         {{ overallTotals.mainBrand1Target1.before_discount.toFixed(2) }}</p>
                       <p><span class="bg-warning p-2 text-white">Cosmetic After Discount:</span>
                         {{ overallTotals.mainBrand1Target1.after_discount.toFixed(2) }}</p>
                       <p><span class="bg-warning p-2 text-white">ليست وكالتنا Before Discount:</span>
                         {{ overallTotals.mainBrand0.before_discount.toFixed(2) }}</p>
                     <p><span class="bg-warning p-2 text-white">ليست وكالتنا After Discount:</span>
                       {{ overallTotals.mainBrand0.after_discount.toFixed(2) }}</p>
                       <p><span class="bg-warning p-2 text-white">Total Before Discount:</span>
                         {{
                           (overallTotals.mainBrand2.before_discount + overallTotals.mainBrand1Target1.before_discount + overallTotals.mainBrand0.before_discount).toFixed(2)
                         }}</p>
                       <p><span class="bg-warning p-2 text-white">Total After Discount:</span> {{
                           (overallTotals.mainBrand2.after_discount + overallTotals.mainBrand1Target1.after_discount + overallTotals.mainBrand0.after_discount).toFixed(2)
                         }}</p>
                       <p><span class="bg-warning p-2 text-white">Commission Loris:</span> {{ totalCommissionLoris.toFixed(2) }}
                       </p>  Display total commission
                       <p><span class="bg-warning p-2 text-white">Commission Cosmetic:</span> {{totalCommissionCosmetic.toFixed(2)}}</p>
                       <p><span class="bg-warning p-2 text-white">Commission Not our brand:</span>
                         {{ totalCommissionNotBrand.toFixed(2) }}</p>
                                 <p>Profit: {{ overallTotals.mainBrand2.after_discount + overallTotals.mainBrand1Target1.after_discount + overallTotals.mainBrand0.after_discount - (totalCommission) }}</p>
                       <p><span class="bg-warning p-2 text-white">Loris Target:</span>
                         {{ (overallTotals.mainBrand2.after_discount - totalCommissionLoris).toFixed(2) }}</p>
                       <p><span class="bg-warning p-2 text-white">Cosmetic Target:</span>
                         {{ (overallTotals.mainBrand1Target1.after_discount - totalCommissionCosmetic).toFixed(2) }}</p>
                              <p><span class="bg-warning p-2 text-white">Not Our brand Target:</span> {{(overallTotals.mainBrand0.after_discount - totalCommissionNotBrand).toFixed(2) }}</p>

                       <p><span class="bg-danger text-white p-2">Loris Profit:</span>
                         {{ lorisProfit }}
                       </p>

                       <p><span class="bg-danger text-white p-2">Cosmetic Profit:</span>
                         {{ cosmeticProfit }}
                       </p>

                       -->

          </div>


          <table class="table table-striped table-hover">
            <thead>
            <tr>
              <th>User Name</th>
              <th>Loris Before Discount</th>
              <th>Loris After Discount</th>
              <th>Cosmetic Before Discount</th>
              <th>Cosmetic After Discount</th>
              <th>ليست وكالتنا Before Discount</th>
              <th>ليست وكالتنا After Discount</th>
              <th>Commission</th>
              <th>Transfer</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(result, index) in sortedResults" :key="index">
              <td>{{ result.first_name }} {{ result.last_name }}</td>
              <td>{{ result.mainBrand2Totals.before_discount.toFixed(2) }}</td>
              <td>{{ result.mainBrand2Totals.after_discount.toFixed(2) }}</td>
              <td>{{ result.mainBrand1Target1Totals.before_discount.toFixed(2) }}</td>
              <td>{{ result.mainBrand1Target1Totals.after_discount.toFixed(2) }}</td>
              <td>{{ result.mainBrand0Totals.before_discount.toFixed(2) }}</td>
              <td>{{ result.mainBrand0Totals.after_discount.toFixed(2) }}</td>
              <td>
                {{
                  (result.mainBrand0Totals.commission + result.mainBrand2Totals.commission + result.mainBrand1Target1Totals.commission).toFixed(2)
                }}
              </td>
              <td>{{ (result.mainBrand2Totals.commission_transfer + result.mainBrand1Target1Totals.commission_transfer + result.mainBrand0Totals.commission_transfer).toFixed(2)}}</td>
<!--              <td>{{ (result.mainBrand0Totals.commission_transfer).toFixed(2)}}</td>-->

              <td>
                <router-link :to="{ name: 'SubAffCom', params: { id: result.user_id } }"
                             class="bg-warning text-white p-3 my-2">
                  View
                </router-link>
              </td>
            </tr>
            <tr v-if="results.length === 0">
              <td colspan="9" class="text-center">No data available</td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';
import {useUserStore} from '../../../../../store';

export default {
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },

  data() {
    return {
      filterFrom: '',
      filterTo: '',
      results: [],
      overallTotals: {
        mainBrand2: {before_discount: 0, after_discount: 0},
        mainBrand1Target1: {before_discount: 0, after_discount: 0},
        mainBrand0: {before_discount: 0, after_discount: 0},
        all: {before_discount: 0, after_discount: 0}
      },
      totalCommissionLoris: 0,
      totalCommissionCosmetic: 0,
      totalCommissionNotBrand: 0,
      totalCommissionTransferLoris:0
    };
  },

  created() {
    this.fetchSalesData();
  },

  computed: {

    sortedResults() {
      return this.results.slice().sort((a, b) => {
        const commissionA = a.mainBrand0Totals.commission + a.mainBrand2Totals.commission + a.mainBrand1Target1Totals.commission;
        const commissionB = b.mainBrand0Totals.commission + b.mainBrand2Totals.commission + b.mainBrand1Target1Totals.commission;
        return commissionB - commissionA; // Sort from highest to lowest
      });
    },

    lorisProfit() {
      const netAmount = this.overallTotals.mainBrand2.after_discount - this.totalCommissionLoris;

      if (netAmount >= 1000 && netAmount <= 2000) {
        return (netAmount * 0.05).toFixed(2);
      } else if (netAmount >= 2001 && netAmount <= 4000) {
        return (netAmount * 0.07).toFixed(2);
      } else if (netAmount >= 4001 && netAmount <= 8000) {
        return (netAmount * 0.08).toFixed(2);
      } else if (netAmount >= 12001 && netAmount <= 17000) {
        return (netAmount * 0.10).toFixed(2);
      } else if (netAmount > 17001) {
        return (netAmount * 0.11).toFixed(2);
      } else {
        return 0;
      }
    },

    cosmeticProfit() {
      const netAmount = this.overallTotals.mainBrand1Target1.after_discount - this.totalCommissionCosmetic;

      if (netAmount >= 500 && netAmount <= 2500) {
        return (netAmount * 0.03).toFixed(2);
      } else if (netAmount > 2500) {
        return (netAmount * 0.05).toFixed(2);
      } else {
        return 0;
      }
    }

  },


  methods: {

    exportToCSV() {

      // Column headers for the CSV
      const headers = [
        'User Name',
        'Loris Before Discount',
        'Loris After Discount',
        'Cosmetic Before Discount',
        'Cosmetic After Discount',
        'ليست وكالتنا Before Discount',
        'ليست وكالتنا After Discount',
        'Commission',
      ];

      // Convert your results data to CSV format
      const csvRows = this.results.map(result => [
        `${result.first_name} ${result.last_name}`,
        result.mainBrand2Totals.before_discount,
        result.mainBrand2Totals.after_discount,
        result.mainBrand1Target1Totals.before_discount,
        result.mainBrand1Target1Totals.after_discount,
        result.mainBrand0Totals.before_discount,
        result.mainBrand0Totals.after_discount,
        result.mainBrand0Totals.commission +
        result.mainBrand2Totals.commission +
        result.mainBrand1Target1Totals.commission
      ]);

      // Join headers and rows
      const csvContent = [
        headers.join(','), // Headers
        ...csvRows.map(row => row.join(',')) // Rows
      ].join('\n');

      // Add BOM to the CSV content to ensure proper encoding
      const BOM = '\uFEFF';  // UTF-8 BOM
      const blob = new Blob([BOM + csvContent], {type: 'text/csv;charset=utf-8;'});
      const url = URL.createObjectURL(blob);

      // Create a download link
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sales_data.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    fetchSalesData(startDate = null, endDate = null) {
      const apiEndpoint = `api/sub-invoices`;
      const params = {
        user_id: this.user?.id,
        from: startDate,
        to: endDate,
      };
      axiosInstance
          .get(apiEndpoint, {params})
          .then(({data}) => {
            console.log(data); // Add this line to debug
            this.results = data.results;
            this.overallTotals = data.overallTotals;
            this.calculateTotalCommissionLoris();
            this.calculateTotalCommissionCosmetic();
            this.calculateTotalCommissionNotBrand();
            this.calculateTotalCommissionTransferLoris();
          })
          .catch(error => {
            console.error('API call failed:', error);
            this.results = [];
            this.overallTotals = {
              mainBrand2: {before_discount: 0, after_discount: 0},
              mainBrand1Target1: {before_discount: 0, after_discount: 0},
              mainBrand0: {before_discount: 0, after_discount: 0},
              all: {before_discount: 0, after_discount: 0}
            };
          });
    },

    applyDateFilter() {
      if (this.filterFrom && this.filterTo) {
        const formattedStartDate = new Date(this.filterFrom).toISOString().split('T')[0];
        const formattedEndDate = new Date(this.filterTo).toISOString().split('T')[0];
        this.fetchSalesData(formattedStartDate, formattedEndDate);
      } else {
        this.fetchSalesData();
      }
    },

    calculateTotalCommissionTransferLoris() {
      this.totalCommissionTransferLoris = this.results.reduce((acc, result) => {
        return acc + (result.mainBrand2Totals.commissionTransfer || 0);
      }, 0);
    },

    calculateTotalCommissionLoris() {
      this.totalCommissionLoris = this.results.reduce((acc, result) => {
        return acc + (result.mainBrand2Totals.commission || 0);
      }, 0);
    },

    calculateTotalCommissionCosmetic() {
      this.totalCommissionCosmetic = this.results.reduce((acc, result) => {
        return acc + (result.mainBrand1Target1Totals.commission || 0);
      }, 0);
    },

    calculateTotalCommissionNotBrand() {
      this.totalCommissionNotBrand = this.results.reduce((acc, result) => {
        return acc + (result.mainBrand0Totals.commission || 0);
      }, 0);
    },

  },
};
</script>
