<template>
  <div class="main-panel ms-5">
    <div class="content-wrapper container">
      <!-- Add brand selection dropdown -->

      <div class="row">

        <div v-if="successMessage" class="success-message-fixed">
          {{ successMessage }}
        </div>

        <div class="col-6">
          <label for="fromDate">From Date:</label>
          <input type="date" class="form-control" v-model="fromDate" @change="orderLoad">
        </div>
        <div class="col-6">
          <label for="toDate">To Date:</label>
          <input type="date" class="form-control" v-model="toDate" @change="orderLoad">
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <label for="search">Search by Title or SKU:</label>
          <input type="text" class="form-control" v-model="searchQuery" @input="orderLoad">
        </div>
      </div>


      <div class="mt-5">
        <label for="brand">Select Brand:</label>
        <select v-model="selectedBrand" @change="orderLoad" class="form-control mb-4">
          <option value="">All Brands</option>
          <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand.brand_name }}</option>
        </select>
      </div>


      <div class="row mt-4 mb-4">
        <div class="col-6">
          <label for="sortColumn">Sort By:</label>
          <select class="form-control" v-model="selectedSortColumn" @change="sortTable">
            <option v-for="column in availableSortColumns" :key="column" :value="column">
              {{ column }}
            </option>
          </select>
        </div>

        <div class="col-6"><label for="sortDirection">Sort Order:</label>
          <select class="form-control" v-model="selectedSortOrder" @change="sortTable">
            <option value="asc">From Min To Max</option>
            <option value="desc">From Max To Min</option>
          </select>
        </div>
      </div>

      <div v-if="showZoomedImage" id="zoomedImageContainer" style="width: 100px; height: 100px;">
        <div class="zoomedImageContent" style="width: 100%; height: 100%;">
          <span class="close" @click="closeZoomedImage">&times;</span>
          <img :src="zoomedImageSrc" alt="Zoomed Image" style="max-width: 100%; max-height: 100%;">
        </div>
      </div>
      <button @click="downloadCSV">Export as CSV</button>


      <div class="card">
        <div class="card-body">
          <h3 class="mb-5">Orders Panel</h3>

          <table class="responsive table-responsive table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Product Image</th>
              <th>Product Name</th>
              <th>Product Sku</th>
              <th>Ordered Times</th>
              <th>Sold Quantity</th>
              <th>Current quantity</th>
              <th>Supplier Name</th>
              <th>Expiry Date</th>
              <th>Minimum Quantity</th>

            </tr>
            </thead>
            <tbody>
            <tr v-for="(allOrder, index) in allOrder" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <th><img @click="zoomImage(allOrder.product_image)"
                       :src="'https://api.gocami.com/product_detail/' + allOrder.product_image" width="50"
                       height="50"/></th>
              <td>
                <router-link class="link-to"
                             :to="{ name: 'AffiliateOrderView', params: { id: allOrder.product_id } }">{{
                    allOrder.product_name
                  }}
                </router-link>
              </td>
              <td>{{ allOrder.product_sku }}</td>
              <td class="text-primary fw-bold">{{ allOrder.ordered_times }}</td>
              <td class="text-success fw-bold">{{ allOrder.sold_quantity }}</td>
              <td v-if="allOrder.current_quantity == 0" class="text-white bg-danger fw-bold">{{
                  allOrder.current_quantity
                }}
              </td>
              <td v-else-if="allOrder.current_quantity < 10" class="text-white bg-warning fw-bold">{{
                  allOrder.current_quantity
                }}
              </td>
              <td v-else class="text-danger fw-bold">{{ allOrder.current_quantity }}</td>
              <td v-if="allOrder.supplier_name" class="bg-primary">{{ allOrder.supplier_name }}</td>
              <td v-else>-</td>
              <td :class="getExpiryClass(allOrder.expiry_date)" v-if="allOrder.expiry_date">{{
                  allOrder.expiry_date
                }}
              </td>
              <td v-else>No Expiry Date</td>

              <td>
                <input type="number" v-model="allOrder.min_quantity" @change="updateMainPrice(allOrder)">
              </td>

            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
  name: "CartInvoice",

  data() {
    return {
      successMessage: '', // Initialize success message
      mainPrice: [],
      allOrder: [],
      brands: [],
      suppliers: [],
      selectedBrand: '',
      selectedSupplier: '',
      fromDate: '',
      toDate: '',
      sortColumn: null,
      sortDirection: 'asc', // default sorting direction
      availableSortColumns: ['current_quantity', 'ordered_times', 'expiry_date'],
      selectedSortColumn: 'current_quantity', // default column to sort by
      selectedSortOrder: 'asc', // default sorting order
      updatedMainPrice: null,
      showZoomedImage: false,
      zoomedImageSrc: ''
    };
  },

  created() {
    this.orderLoad();
  },

  methods: {

    zoomImage(imageSrc) {
      console.log('Zoom image clicked');
      console.log('Image source:', imageSrc);
      this.zoomedImageSrc = 'https://api.gocami.com/product_detail/' + imageSrc;
      console.log('Zoomed image source:', this.zoomedImageSrc);
      this.showZoomedImage = true;
    },

    closeZoomedImage() {
      console.log('Close zoomed image clicked');
      this.showZoomedImage = false;
    },

    updateMainPrice(allOrder) {
      axiosInstance.put(`api/accounting-update-min-quantity/${allOrder.product_id}`, {min_quantity: allOrder.min_quantity})
          .then(response => {
            console.log(response);
            this.successMessage = 'Minimum Quantity updated successfully'; // Set success message

            // Hide the success message after 5 seconds
            setTimeout(() => {
              this.successMessage = '';
            }, 5000);

          })
          .catch(error => {
            console.error(error);
          });
    },

    getExpiryClass(expiryDate) {
      const expiryDateObj = new Date(expiryDate);
      const currentDate = new Date();
      const monthsDifference = (expiryDateObj.getFullYear() - currentDate.getFullYear()) * 12 + (expiryDateObj.getMonth() - currentDate.getMonth());

      if (monthsDifference <= 0) {
        return 'bg-danger';
      } else if (monthsDifference === 1) {
        return 'bg-warning';
      } else {
        return 'bg-success';
      }
    },


    sortTable() {
      // Sort the products array based on the selected column and direction
      this.allOrder.sort((a, b) => {
        const modifier = this.selectedSortOrder === 'desc' ? -1 : 1;

        // Check if the selected column is 'expiry_date'
        if (this.selectedSortColumn === 'expiry_date') {
          const dateA = new Date(a.expiry_date);
          const dateB = new Date(b.expiry_date);
          if (dateA < dateB) return -1 * modifier;
          if (dateA > dateB) return 1 * modifier;
          return 0;
        } else {
          // For other columns, perform regular comparison
          if (a[this.selectedSortColumn] < b[this.selectedSortColumn]) return -1 * modifier;
          if (a[this.selectedSortColumn] > b[this.selectedSortColumn]) return 1 * modifier;
          return 0;
        }
      });
    },

    generateCSV() {
      const header = ['Product Name', 'Product SKU', 'Ordered Times', 'Sold Quantity', 'Current Quantity', 'Supplier Name', 'Expiry Date'];
      const csvRows = [];

      // Add header row
      csvRows.push(header.join(','));

      // Add data rows
      this.allOrder.forEach(allOrder => {
        const rowData = [
          `"${allOrder.product_name}"`,
          `"${allOrder.product_sku}"`,
          allOrder.ordered_times,
          allOrder.sold_quantity,
          allOrder.current_quantity,
          allOrder.supplier_name ? `"${allOrder.supplier_name}"` : '', // Handle cases where supplier name is null
          allOrder.expiry_date ? `"${allOrder.expiry_date}"` : 'No Expiry Date' // Handle cases where expiry date is null
        ];
        csvRows.push(rowData.join(','));
      });

      // Combine rows into a single CSV string
      const csvContent = csvRows.join('\n');

      return csvContent;
    },

    downloadCSV() {
      const csvContent = this.generateCSV();
      const blob = new Blob([csvContent], {type: 'text/csv'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'orders.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    orderLoad() {
      console.log('Order load method called');
      console.log('Search query:', this.searchQuery);
      var endpoint = `api/super-admin-content`;
      var params = {
        brand_id: this.selectedBrand,
        supplier_id: this.selectedSupplier,
        from_date: this.fromDate,
        to_date: this.toDate,
        sort_column: this.selectedSortColumn,
        sort_order: this.selectedSortOrder,
        search: this.searchQuery // Combine search query for title and sku
      };

      axiosInstance.get(endpoint, {params}).then(({data}) => {
        console.log(data);
        this.brands = data.brands;
        this.allOrder = data.products;
        this.suppliers = data.suppliers;
      });
    },

  },
};
</script>

<style scoped>

.success-message-fixed {
  position: fixed;
  top: 20px; /* Adjust as needed */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
  background-color: green;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.link-to:hover {
  color: red;
}

.bg-danger {
  background-color: red;
}

.bg-warning {
  background-color: orange;
}

.bg-success {
  background-color: green;
}

/* New styles for zoomed image container */
#zoomedImageContainer {
  display: block;
  /* Add this line to make the container visible */
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.9); */
  overflow: auto;
}

.zoomedImageContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 999999 !important;
}

.zoomedImageContent img {
  z-index: 99999 !important;
  max-width: 100%;
  max-height: 100%;
  border: 2px solid #fff;
  /* Optional: Add a border around the image */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  /* Optional: Add a box shadow */
}

.close {
  color: red !important;
  position: absolute;
  top: 10px;
  right: 110px;
  font-size: 30px !important;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.close:hover {
  transform: scale(1.2);
  /* Optional: Increase the size of the close icon on hover */
}
</style>